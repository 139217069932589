import Auth from "@/components/General/Auth/auth";
import Client from "@/components/Admins/Clients/clients";
import Users from "@/components/Tenants/Users/users";
import Admin from "@/components/Admins/Account/Admin/admins";
import store from "@/core/services/store";

class Tenant {
  async getTenant() {
    const userType = store.getters.userType;
    let data = {};
    if (userType !== "admin") {
      data = this.getTenantClient();
    } else {
      data = this.getSelectedTenant();
    }
    return data;
  }

  async getTenantClient() {
    let data = {};
    await Auth.getMeTenant().then(response => {
      data = response.data.data.tenantable;
    });
    return data;
  }

  async getSelectedTenant() {
    const selectedClient = store.getters.selectedClient;
    if (selectedClient === null) {
      return {};
    }
    let data = {};
    await Client.get(selectedClient.id).then(response => {
      data = response.data.data;
    });
    return data;
  }

  async getPartner() {
    // const userType = Auth.getUserType();
    return this.getSelectedPartner();
    /*
        let data;
        if (userType === "admin") {
            data = this.getSelectedPartner();
        } else {
            data = this.getTenantClient();
        }
        return data;
        */
  }

  async getSelectedPartner() {
    /*
    const selectedPartner = Partner.getSelectedPartner();
    if (
      selectedPartner.partnerId === undefined ||
      selectedPartner.partnerId === null
    ) {
      return {};
    }
    let data = {};
    await Partner.get(selectedPartner.partnerId).then(response => {
      data = response.data.data;
    });
    return data;
    */

    return store.getters.selectedPartner ?? {};
  }

  getMainContact(id = null) {
    const userType = store.getters.userType;
    if (userType === "admin") {
      return Admin.get(id);
    }
    return Users.getMainContact();
  }
}

export default new Tenant();
