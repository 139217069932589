<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title d-flex align-items-center">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("support.title") }}
        </h3>
      </div>
      <div class="card-toolbar">
        <button
          :class="$v.$invalid ? 'btn-secondary' : 'btn-success'"
          class="btn"
          :disabled="$v.$invalid || isBusy > 0"
          @click="sendRequest"
        >
          {{ $t("general.send") }}
        </button>
      </div>
    </div>
    <v-progress-linear v-if="isBusy !== 0" indeterminate color="primary" />
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("support.name") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              v-model="data.name"
              type="text"
              class="form-control"
              :class="!$v.data.name.required ? 'is-invalid' : 'is-valid'"
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.data.name.required"
              id="projectName-feedback"
              class="ml-1"
            >
              {{ $t("validation.required.name") }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("support.email") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              v-model="data.email"
              type="email"
              class="form-control"
              :class="
                !$v.data.email.required || !$v.data.email.email
                  ? 'is-invalid'
                  : 'is-valid'
              "
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.data.email.required"
              class="ml-1"
            >
              {{ $t("validation.required.email") }}
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.data.email.email" class="ml-1">
              {{ $t("validation.email.email") }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-center">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label">
            {{ $t("support.subject") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <b-form-input
              v-model="data.subject"
              type="text"
              class="form-control"
              :class="!$v.data.subject.required ? 'is-invalid' : 'is-valid'"
              @input="validateFields"
            />
            <b-form-invalid-feedback
              v-if="!$v.data.subject.required"
              class="ml-1"
            >
              {{
                $t("validation.required.general", {
                  label: $t("support.subject")
                })
              }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-top">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label mt-2">
            {{ $t("support.comment") }} <span class="text-danger">*</span>
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <quill-editor
              ref="myTextEditor"
              class="editor"
              :options="editorOptions"
              :value="data.comment"
              :class="!$v.data.comment.required ? 'is-invalid' : 'is-valid'"
              @change="onEditorChange"
            />
            <b-form-invalid-feedback
              v-if="!$v.data.comment.required"
              class="ml-1"
            >
              {{
                $t("validation.required.general", {
                  label: $t("support.comment")
                })
              }}
            </b-form-invalid-feedback>
          </div>
        </div>

        <div class="form-group row align-items-top">
          <label class="col-xl-2 col-lg-2 col-md-3 col-form-label mt-2">
            {{ $t("support.files") }}
          </label>
          <div class="col-md-9 col-lg-8 col-xl-8">
            <file-upload
              ref="upload"
              :multiple="true"
              :maximum="maxFiles"
              :directory="false"
              :create-directory="false"
              :drop="true"
              :drop-directory="true"
              style="width: 100%"
              @input-file="inputFile"
            >
              <div class="card card-body">
                <h3>{{ $t("media.dropFilesHereOrClickToUpload") }}</h3>
              </div>
              <b-alert v-if="files.length >= maxFiles" show variant="danger">
                {{ $t("media.maxFileLimitReached", { limit: maxFiles }) }}
              </b-alert>
            </file-upload>
            <table class="table datatable">
              <tbody>
                <tr v-for="(entry, key) in files" :key="key">
                  <td style="width: 50px">
                    <div class="col-auto symbol symbol-50 py-0">
                      <div
                        v-if="entry.type.includes('image')"
                        class="symbol-label symbol-label-profile"
                        style="cursor: zoom-in"
                        :style="
                          'background-image: url(' +
                          entry.thumb +
                          '); background-color: #F3F6F9;'
                        "
                      ></div>
                      <div v-else class="symbol-label">
                        <i
                          v-if="entry.type.includes('pdf')"
                          class="fal fa-file-pdf"
                        ></i>
                        <i
                          v-else-if="entry.type.includes('zip')"
                          class="fal fa-file-archive"
                        ></i>
                        <i v-else class="fal fa-box-open-full"></i>
                      </div>
                    </div>
                  </td>
                  <td>{{ entry.name }}</td>
                  <td v-if="entry.size > 1000000">
                    {{ (entry.size / 1000 / 1000).toFixed(2) }} MB
                  </td>
                  <td v-else>{{ (entry.size / 1000).toFixed(2) }} KB</td>
                  <td style="width: 50px">
                    <b-button
                      size="sm"
                      variant="danger"
                      @click="removeFile(key)"
                    >
                      <i class="fal fa-trash pa-0" />
                    </b-button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { email, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import zendesk from "@/components/General/HelpArea/zendesk";
import Client from "@/components/Admins/Clients/clients";
import Tenant from "@/components/Tenants/tenant";
import Partner from "@/components/Admins/Partners/partners";
import debounce from "lodash/debounce";
import FileUpload from "vue-upload-component";

export default {
  components: {
    FileUpload
  },
  data() {
    return {
      isBusy: 0,
      data: {
        name: "",
        email: "",
        subject: "",
        comment: "",
        fileTokens: []
      },
      maxFiles: parseInt(process.env.VUE_APP_MAX_FILES_UPLOAD) || 20,
      files: [],
      editorOptions: {
        placeholder: this.$t("projects.description"),
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],
            ["clean"]
          ]
        },
        formats: [
          "background",
          "bold",
          "color",
          "font",
          "code",
          "italic",
          "link",
          "size",
          "strike",
          "script",
          "underline",
          "blockquote",
          "header",
          "indent",
          "list",
          "align",
          "direction",
          "code-block",
          "formula"
          // 'image'
          // 'video'
        ]
      }
    };
  },
  validations: {
    data: {
      name: { required },
      email: { required, email },
      subject: { required },
      comment: { required }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  },
  methods: {
    setBreadcrumbs() {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: this.$t("menu.helpSupport"),
          route: "/help/contact-person"
        }
      ]);
    },
    loadClient() {
      const selectedClient = this.$store.getters.selectedClient;
      if (!selectedClient) {
        return;
      }
      this.isBusy++;
      Client.get(selectedClient.id)
        .then(response => {
          this.data.comment +=
            this.$t("support.client", { value: response.data.data.name }) +
            "<br>";

          if (response.data.data.main_contact_id) {
            this.isBusy++;
            Tenant.getMainContact(response.data.data.main_contact_id)
              .then(adminResponse => {
                this.data.comment +=
                  this.$t("support.contactPerson", {
                    value: adminResponse.data.data.name
                  }) + "<br>";
                this.isBusy--;
              })
              .catch(() => {
                this.isBusy--;
              });
          }

          if (response.data.data.partner_id) {
            this.isBusy++;
            Partner.get(response.data.data.partner_id)
              .then(partnerResponse => {
                this.data.comment +=
                  this.$t("support.partner", {
                    value: partnerResponse.data.data.name
                  }) + "<br>";
                this.isBusy--;
              })
              .catch(() => {
                this.isBusy--;
              });
          }

          this.isBusy--;
        })
        .catch(() => {
          this.isBusy--;
        });
    },
    loadPartner() {
      const selectedPartner = this.$store.getters.selectedPartner;
      if (!selectedPartner) {
        return;
      }

      this.isBusy++;
      Partner.get(selectedPartner.id)
        .then(response => {
          this.data.comment += this.$t("support.partner", {
            value: response.data.data.name
          });

          if (response.data.data.main_contact_id) {
            this.isBusy++;
            Tenant.getMainContact(response.data.data.main_contact_id)
              .then(adminResponse => {
                this.data.comment +=
                  "<br>" +
                  this.$t("support.contactPerson", {
                    value: adminResponse.data.data.name
                  });
                this.isBusy--;
              })
              .catch(() => {
                this.isBusy--;
              });
          }

          this.isBusy--;
        })
        .catch(() => {
          this.isBusy--;
        });
    },
    sendRequest() {
      this.isBusy++;

      if (!this.files || this.files.length === 0) {
        this.createTicket();
        return;
      }

      const data = new FormData();
      this.files.forEach(entry => {
        data.append("files[]", entry.file);
      });

      zendesk
        .uploadFile(data)
        .then(response => {
          this.data.fileTokens = response.data.data.tokens;
          this.createTicket();
        })
        .catch(error => {
          console.log(error);
          this.isBusy--;
        });
    },
    createTicket() {
      zendesk
        .createTicket(this.data)
        .then(response => {
          if (response.data.data.success) {
            this.$toast.fire({
              icon: "success",
              title: this.$t("support.saved")
            });
          }
          this.isBusy--;
        })
        .catch(error => {
          console.log(error);
          this.isBusy--;
        });
    },
    validateFields() {
      this.$v.$touch();
    },
    onEditorChange: debounce(function (value) {
      let html = value.html;
      this.data.comment = html;
    }, 466),
    inputFile(file) {
      if (!file) {
        return;
      }
      let self = this;
      file.thumb = "";
      const reader = new FileReader();
      reader.onload = event => {
        file.thumb = event.target.result;
      };
      reader.readAsDataURL(file.file);
      setTimeout(function () {
        self.files.push(file);
      }, 200);
    },
    removeFile(index) {
      this.$refs.upload.remove(this.files[index]);
      this.files.splice(index, 1);
    }
  },
  mounted() {
    this.setBreadcrumbs();
    if (this.$store.getters.userType === "client") {
      this.loadClient();
    } else if (this.$store.getters.userType === "partner") {
      this.loadPartner();
    }

    const to = this.$route.params.to;
    if (to) {
      this.data.subject = this.$t("support.requestTo", { to: to });
    }

    this.data.email = this.currentUserPersonalInfo.email;
    this.data.name = this.getFullName;
  }
};
</script>

<style>
.ql-container {
  min-height: 25vh !important;
}

.table tr:first-child td {
  border: none;
}
</style>
