import ApiService from "@/core/services/api.service";
import store from "@/core/services/store";

ApiService.setHeader("x-api-key", store.getters.apiToken);
if (store.getters.apiTokenPartner) {
  ApiService.setHeader("x-partner-key", store.getters.apiTokenPartner);
}
let url = process.env.VUE_APP_API_TENANT;
if (process.env.VUE_APP_VERSION >= 2) {
  url = process.env.VUE_APP_API_ADMIN;
}

class Zendesk {
  uploadFile(data) {
    return ApiService.post(url + "/zendesk/uploadFile", data);
  }

  createTicket(data) {
    return ApiService.post(url + "/zendesk/createTicket", data);
  }
}

export default new Zendesk();
